import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { FaNetworkWired, FaDatabase, FaMobileAlt, FaBrain } from 'react-icons/fa'
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1 className="about-page-title">
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['S','e','r','v','i','c','e','s']}
              idx={15}
            />
          </h1>
          <div className="services-grid">
            <div className="service-item">
              <div className="service-header">
                <FaNetworkWired className="service-icon" />
                <h2>API</h2>
              </div>
              <p>
                We design and develop robust, scalable APIs that ensure secure, seamless integration between your business systems.
              </p>
            </div>
            <div className="service-item">
              <div className="service-header">
                <FaDatabase className="service-icon" />
                <h2>DATA INTEGRATION</h2>
              </div>
              <p>
                Our solutions unify diverse data sources into a cohesive framework, enabling real‑time analytics and smarter decision‑making.
              </p>
            </div>
            <div className="service-item">
              <div className="service-header">
                <FaMobileAlt className="service-icon" />
                <h2>WEB &amp; MOBILE APPS</h2>
              </div>
              <p>
                We craft innovative web and mobile applications that deliver exceptional user experiences and drive digital transformation.
              </p>
            </div>
            <div className="service-item">
              <div className="service-header">
                <FaBrain className="service-icon" />
                <h2>AI &amp; ML</h2>
              </div>
              <p>
                Leverage the power of artificial intelligence and machine learning to automate processes, extract insights, and stay ahead of the curve.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Loader type="ball-rotate" />
    </>
  )
}

export default About
