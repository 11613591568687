import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import deepakImage from '../../assets/images/deepak.jpg'
import krishnaImage from '../../assets/images/krishna.jpg'
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1 className="about-page-title">
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'u', 's']}
              idx={15}
            />
          </h1>

          <div className="developers-container">
            {/* Deepak Mahat */}
            <div className="developer">
              <img src={deepakImage} alt="Deepak Mahat" className="developer-image flipped" />
              <div className="developer-info">
                <h3 className="developer-name">Deepak Mahat</h3>
                <div className="developer-contact">
                 <div>
                  <svg className="email-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20 4H4a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2zM4 6h16v.01l-8 4.99-8-4.99V6zm0 12V9l7.4 4.6a1 1 0 001.2 0L20 9v9H4z" />
                  </svg>
                  <span>deepak@mahat.au</span>
                  </div>
                  <div>
                  <svg className="email-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20.487 15.14l-3.179-.363a2.016 2.016 0 00-1.618.573l-2.248 2.256a15.382 15.382 0 01-6.871-6.876l2.254-2.248a2.015 2.015 0 00.573-1.618L8.032 3.514A2.01 2.01 0 006.003 2H3.516A1.514 1.514 0 002 3.516c0 10.285 8.199 18.484 18.484 18.484A1.514 1.514 0 0022 20.484v-2.487a2.015 2.015 0 00-1.513-1.857z"/>
                  </svg>
                  <span>0423528740</span>
                  </div>
                </div>
                <p className="developer-role">Software Engineer & IT Consultant</p>
                {/* <p className="developer-description">
                  He has more than 15 years of experience in the Australian software
                  industry. Completed his Masters of Software Engineering from the
                  University of Sydney and has worked on diverse projects for over a decade.
                </p> */}
                <a href="https://deepak.mahat.au" className="linkedin-btn">
                  Know More
                </a>
              </div>
            </div>

            {/* Krishna Mahat */}
            <div className="developer">
              <img src={krishnaImage} alt="Krishna Mahat" className="developer-image flipped" />
              <div className="developer-info">
                <h3 className="developer-name">Krishna Mahat</h3>
                <div className="developer-contact">
                  <div>
                  <svg className="email-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20 4H4a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2zM4 6h16v.01l-8 4.99-8-4.99V6zm0 12V9l7.4 4.6a1 1 0 001.2 0L20 9v9H4z" />
                  </svg>
                  <span>krishna@mahat.au</span>
                  </div>
                  <div>
                  <svg className="email-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20.487 15.14l-3.179-.363a2.016 2.016 0 00-1.618.573l-2.248 2.256a15.382 15.382 0 01-6.871-6.876l2.254-2.248a2.015 2.015 0 00.573-1.618L8.032 3.514A2.01 2.01 0 006.003 2H3.516A1.514 1.514 0 002 3.516c0 10.285 8.199 18.484 18.484 18.484A1.514 1.514 0 0022 20.484v-2.487a2.015 2.015 0 00-1.513-1.857z"/>
                  </svg>
                  <span>0493517297</span>
                  </div>
                </div>
                <p className="developer-role">Web Application Engineer</p>
                {/* <p className="developer-description">
                  Web Application Engineer with 5+ years of experience in the Australian IT industry.
                  Holds a Master’s in Information Technology from Torrens University.
                </p> */}
                <a href="https://www.linkedin.com/in/krishna-mahat-0b6406113" className="linkedin-btn">
                  Know More
                </a>
              </div>
            </div>
          </div>

          <p className="about-summary">
            We bring over 15 years of professional expertise in software engineering, ensuring your projects are delivered with the highest quality and within a budget that fits your needs.
          </p>
        </div>
      </div>
      <Loader type="ball-rotate" />
    </>
  )
}

export default About
