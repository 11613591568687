import { Outlet } from 'react-router-dom'
import Sidebar from '../Sidebar/'
import { useEffect, useRef } from 'react'
import './index.scss'

const Layout = () => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return
    const ctx = canvas.getContext('2d')

    const resizeCanvas = () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    }

    resizeCanvas() // Set initial size
    window.addEventListener('resize', resizeCanvas) // Update size on resize

    let stars = []
    const FPS = 60
    const numStars = 50
    const mouse = { x: 0, y: 0 }

    for (let i = 0; i < numStars; i++) {
      stars.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 1 + 1,
        vx: Math.floor(Math.random() * 50) - 25,
        vy: Math.floor(Math.random() * 50) - 25
      })
    }

    const distance = (point1, point2) => {
      const xs = point2.x - point1.x
      const ys = point2.y - point1.y
      return Math.sqrt(xs * xs + ys * ys)
    }

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.globalCompositeOperation = 'lighter'

      stars.forEach((star) => {
        ctx.fillStyle = '#D3D3D3'
        ctx.beginPath()
        ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2)
        ctx.fill()
        ctx.fillStyle = 'black'
        ctx.stroke()
      })

      ctx.beginPath()
      stars.forEach((star, i) => {
        ctx.moveTo(star.x, star.y)
        if (distance(mouse, star) < 150) ctx.lineTo(mouse.x, mouse.y)
        stars.forEach((star2) => {
          if (distance(star, star2) < 150) {
            ctx.lineTo(star2.x, star2.y)
          }
        })
      })
      ctx.lineWidth = 0.05
      ctx.strokeStyle = 'gray'
      ctx.stroke()
    }

    const update = () => {
      stars.forEach((star) => {
        star.x += star.vx / FPS
        star.y += star.vy / FPS

        if (star.x < 0 || star.x > canvas.width) star.vx = -star.vx
        if (star.y < 0 || star.y > canvas.height) star.vy = -star.vy
      })
    }

    const animate = () => {
      draw()
      update()
      requestAnimationFrame(animate)
    }

    canvas.addEventListener('mousemove', (e) => {
      mouse.x = e.clientX
      mouse.y = e.clientY
    })

    animate()

    return () => {
      stars = []
      window.removeEventListener('resize', resizeCanvas)
      canvas.removeEventListener('mousemove', () => {})
    }
  }, [])

  return (
    <div className="App">
      <Sidebar />
      <div className="page">
        <Outlet />
      </div>
      <canvas ref={canvasRef} className="background-canvas"></canvas>
    </div>
  )
}

export default Layout
