import './index.scss'

// AnimatedLetters.jsx
const AnimatedLetters = ({ letterClass, strArray, idx }) => {
  const words = []
  let currentWord = []

  // Group letters into words using space as a delimiter
  strArray.forEach((char) => {
    if (char === ' ') {
      if (currentWord.length > 0) {
        words.push(currentWord)
        currentWord = []
      }
      // Preserve the space as a separate group
      words.push([' '])
    } else {
      currentWord.push(char)
    }
  })
  if (currentWord.length > 0) words.push(currentWord)

  let charIdx = idx
  return (
    // Wrap all the words in a container with a class for styling
    <span className="animated-letters-container">
      {words.map((word, wordIdx) => {
        // If it's just a space, render it directly
        if (word.length === 1 && word[0] === ' ') {
          return ' '
        }
        // Wrap each word in its own container that prevents breaking inside the word
        return (
          <span key={wordIdx} className="word-container">
            {word.map((char, i) => (
              <span key={i} className={`${letterClass} _${charIdx++}`}>
                {char}
              </span>
            ))}
            &nbsp; {/* Add a space after each word */}
          </span>
        )
      })}
    </span>
  )
}

export default AnimatedLetters
