import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  const nameArray = ['T', 'a', 'i', 'l', 'o', 'r', 'm', 'a', 'd', 'e', ' ', 's', 'o', 'l', 'u', 't', 'i', 'o', 'n', 's', ' ', 'i', 'n', ' ', 'b', 'u', 'd', 'g', 'e', 't']

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  }, [])

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1 className="introtext">
            <span className={`${letterClass} _11 logo-class`}>M</span>
            <span className={`${letterClass} _12 logo-class`}>a</span>
            <span className={`${letterClass} _13 logo-class`}>h</span>
            <span className={`${letterClass} _14 logo-class`}>a</span>
            <span className={`${letterClass} _15 logo-class`}>t</span>
            <span className={`${letterClass} _16 logo-class`}>.</span>
            <span className={`${letterClass} _17 logo-class`}>a</span>
            <span className={`${letterClass} _18 logo-class`}>u</span>
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={50}
            />
            <br />
          </h1>
          <h2>API / DATA INTEGRATION / WEB & MOBILE APPS / AI & ML</h2>
          <br />
          <Link to="/contact" className="flat-home-button">
            CONTACT US
          </Link>
        </div>
      </div>
      <Loader type="ball-rotate" />
    </>
  )
}

export default Home
